@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.nav {
  display: flex;
  @media screen and (max-width: 815px) {
    display: none;
  }

  &__link {
    background-color: transparent;
    color: $white;
    @include main-nav;
    @media screen and (max-width: 631px) {
    }

    &.active {
      color: $accent-blue;
    }
  }
}

.mobileNav {
  display: none;
  @media screen and (max-width: 815px) {
    display: block;
  }
}
