@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.divider {
  @include divider;

  &Vertical {
    @include divider--vertical;
    @media screen and (max-width: 815px) {
      display: none;
    }

    &__tablet {
      @media screen and (max-width: 815px) {
        @include divider;
        margin-top: 2.4rem;
        width: 100%;
      }
    }
  }
}
