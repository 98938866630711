@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.section {
  &__container {
    @include about-section;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 727px) {
      flex-direction: column;
    }
  }

  &__imgWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__imgContainer {
    max-width: 31.6rem;
    width: 100%;
  }

  &__img {
    @include headshot;
  }
}

.about {
  width: 100%;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  @media screen and (max-width: 727px) {
    margin-right: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    @include section-heading;
  }

  &__heading {
    flex-shrink: 0;
  }

  &__copy {
    @include about-copy;
  }
}
