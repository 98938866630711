@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.section {
  @include background-light;

  &__container {
    @include section-padding;
  }

  &__heading {
    margin-bottom: 3.2rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  width: 100%;

  &__input {
    @include form-inputs;

    &Check {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
