//*** COLORS
$dark-blue: #121e27;
$light-blue: #436aa4;
$accent-blue: #73d7ee;
$accent-yellow: #fffd00;
$white: #fff;

// overlay
$overlay: rgba(255, 255, 255, 0.05);

// shadow
$shadow: rgba(0, 0, 0, 0.25);

// shades
$dark-blue-shade-1: #20334f;

// tints
$light-blue-tint-1: #6186be;
$light-blue-tint-2: #b0c3df;

//*** TYPOGRAPHY
$font-family: "Titillium Web", sans-serif;

//*** BREAKPOINTS
// for use with max-width
$brkpt-mobile: 767px;
// for use with min-width
$brkpt-tablet: 768px;
$brkpt-desktop: 1000px;

//*** PADDING

// naming convention = multipliers
// i.e padding-1 = 1 * 8
$increment: 0.8rem;

$pad-half: calc($increment / 2); // 0.4rem
$pad-1: calc($increment * 1); // 0.8rem
$pad-2: calc($increment * 2); // 1.6rem
$pad-3: calc($increment * 3); // 2.4rem
$pad-4: calc($increment * 4); // 3.2rem
$pad-5: calc($increment * 5); // 4rem
$pad-6: calc($increment * 6); // 4.8rem
$pad-7: calc($increment * 7); // 5.6rem
$pad-8: calc($increment * 8); // 6.4rem
$pad-9: calc($increment * 9); // 7.2rem
$pad-10: calc($increment * 10); // 8rem
