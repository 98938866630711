@use "../../../styles/partials/variables" as *;
@use "../../../styles/partials/mixins" as *;

.project {
  @include project-padding;
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  @media screen and (max-width: 840px) {
    &,
    &:nth-of-type(even) {
      gap: 3rem;
      flex-direction: column;
    }
  }

  // CONTAINERS
  &__mainContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: $pad-2;
    width: 100%;
    flex: 50%;
  }

  // HEADER
  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    width: 100%;
  }

  &__heading {
    @include project-title;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: $pad-2;
  }

  // DESCRIPTION
  &__description {
    @include default-copy;
  }

  // LARGE SCREEN SHOTS
  &__mainImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 50%;
    width: 100%;
  }

  &__screenshot {
    max-width: 100%;
    max-height: 100%;
    // height: 100%;
    // @include screenshot-lg;
    // @include border-radius;
  }
}
