@use "./variables" as *;
@use "./mixins" as *;

//* RESETS *//
html {
  font-size: 62.5%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  @include default-copy;
  letter-spacing: 0.1rem;
  color: $white;
  min-height: 100vh;
  background-color: $dark-blue;
}

a,
a:link,
a:visited {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

button,
form,
input,
textarea {
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

button {
  cursor: pointer;
}

ol,
ul,
nav {
  display: flex;
  flex-direction: column;
  list-style: none;
}

nav {
  flex-direction: row;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
