@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.section {
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__heading {
    @include section-heading;
    flex-shrink: 0;
  }
}
