@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.header {
  &__container {
    @include header;
    gap: 2.4rem;
    @media screen and (max-width: 787px) {
      justify-content: space-around;
    }
  }

  &__logo {
    @include logo;
    cursor: pointer;
    flex-shrink: 0;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
  }

  &__title {
    @include page-heading;
    text-transform: uppercase;
    @media screen and (max-width: 415px) {
      text-align: center;
    }
  }

  &__subtitle {
    @include page-subheading;
    @media screen and (max-width: 415px) {
      display: none;
    }
  }
}

.firstChar {
  font-size: 4.8rem;
  @media screen and (max-width: 900px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 727px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 415px) {
    font-size: 2.4rem;
  }
}

.remainingChars {
  font-size: 4rem;
  @media screen and (max-width: 900px) {
    font-size: 3.2rem;
  }
  @media screen and (max-width: 727px) {
    font-size: 2.4rem;
  }
  @media screen and (max-width: 415px) {
    font-size: 1.8rem;
  }
}
