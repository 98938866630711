@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.section {
  @include background-dark;

  &__container {
    @include section-padding;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: $pad-3;
  }

  &__heading {
    @include section-heading;
  }

  &__divLeft {
    width: 60%;
    @media screen and (max-width: 815px) {
      display: none;
    }
  }

  &__divRight {
    width: 40%;
    @media screen and (max-width: 815px) {
      width: 100%;
    }
  }
}

.exp {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 815px) {
    flex-direction: column;
    gap: 2.4rem;
  }

  &__roles {
    flex: 0 -1;
  }

  &__heading {
    @include section-subheading;
  }

  &__divider {
    margin: 0 6rem;
    @media screen and (max-width: 815px) {
      margin: 0;
    }
  }

  &__list {
    @include list-style;
  }

  &__listItem {
    @include default-copy;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: $pad-half;
    @media screen and (max-width: 535px) {
      font-size: 1.4rem;
    }
  }

  &__company {
    @extend .exp__listItem;
  }
  &__name {
    font-size: 1.8rem;
    @media screen and (max-width: 535px) {
      font-size: 1.6rem;
    }
  }

  &__description {
    padding-left: $pad-2;
  }
}

.skills {
  text-align: right;
  flex-shrink: 0;
  @media screen and (max-width: 815px) {
    text-align: left;
    width: 100%;
  }

  &__heading {
    @include section-subheading--yellow;
  }

  &__list {
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: $pad-1;
    padding-left: $pad-2;
  }
}
