@use "./variables" as *;

//*** MEDIA QUERIES

// TABLET BREAKPOINT
@mixin five-media-break {
  @media screen and (min-width: 500px) {
    @content;
  }
}

@mixin tablet-media-break {
  @media screen and (min-width: $brkpt-tablet) {
    @content;
  }
}

// DESKTOP BREAKPOINT
@mixin desktop-media-break {
  @media screen and (max-width: $brkpt-desktop) {
    @content;
  }
}

// @media screen and (max-width: 835px) {
// }
// @media screen and (max-width: 727px) {
// }
// @media screen and (max-width: 631px) {
// }

//*** LOGO
@mixin logo {
  width: 9rem;
  height: 10rem;
  @media screen and (max-width: 840px) {
    width: calc(9 * 0.835rem);
    height: calc(10 * 0.835rem);
  }

  @media screen and (max-width: 727px) {
    width: calc(9 * 0.727rem);
    height: calc(10 * 0.727rem);
  }
}

//*** TYPOGRAPHY

@mixin page-heading {
  color: $accent-yellow;
  letter-spacing: 0.2rem;
}

@mixin page-subheading {
  font-size: 2rem;
  letter-spacing: 0.1rem;
  @media screen and (max-width: 835px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 727px) {
    font-size: 1.6rem;
  }
}

@mixin main-nav {
  & {
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: $white;
    transition: color 0.4s;
  }
  &:hover,
  &:active {
    color: $accent-blue;
  }
  @media screen and (max-width: 835px) {
    font-size: 1.6rem;
  }
}

@mixin section-heading {
  font-size: 3.6rem;
  line-height: 2.8rem;
  color: $accent-blue;
  margin: 3.2rem 0 2.4rem;
}

@mixin section-subheading {
  color: $light-blue-tint-1;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 2.4rem 0 1.6rem;
}

@mixin section-subheading--yellow {
  @include section-subheading;
  color: $accent-yellow;
}

@mixin project-title {
  color: $accent-yellow;
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-weight: 700;
}

@mixin about-copy {
  font-size: 1.6rem;
  line-height: 2rem;
  @media screen and (max-width: 390px) {
    font-size: 1.4rem;
  }
}

@mixin default-copy {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

//*** ICONS
@mixin icon {
  width: 2.4rem;
  height: 2.4rem;
  stroke: $dark-blue;
  fill: $dark-blue;
  transition: all 0.3s;

  &:hover {
    fill: $accent-yellow;
    stroke: $accent-yellow;
  }
}

//*** MAX-WIDTH
@mixin max-width-rem {
  max-width: 130rem;
  margin: 0 auto;
}

//*** MIN-HEIGHT
@mixin min-height-vh {
  min-height: 100vh;
}

//*** BACKGROUND COLORS
@mixin background-dark {
  background-color: $dark-blue;
}

@mixin background-light {
  background-color: $dark-blue-shade-1;
}

//*** CONTAINERS

@mixin header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 0 3.6rem;
  @include max-width-rem;
  @media screen and (max-width: 1324px) {
    padding: 2.4rem 2rem 3.6rem;
  }
  @include desktop-media-break {
    padding: 2.4rem 1.6rem 3.6rem;
  }
  @media screen and (max-width: 727px) {
    padding: 2rem 1.6rem;
  }
}

@mixin about-section {
  background-color: $overlay;
  padding: 3.2rem 4.5rem 4.8rem;
  @include max-width-rem;
  @media screen and (max-width: 535px) {
    padding: 3.2rem 1.6rem 4.8rem;
  }
}

@mixin headshot {
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.2rem solid $accent-blue;
  background-image: url("../../assets/images/headshot/headshot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 114%;
}

@mixin screenshot-sm {
  aspect-ratio: 16 / 9;
  width: 100%;
}

@mixin screenshot-lg {
  aspect-ratio: 16 / 9;
  border: 0.2rem solid $light-blue-tint-2;
  @include box-shadow;
}

@mixin section-padding {
  background-color: $overlay;
  padding: 3.2rem 4.5rem 6.4rem;
  @include max-width-rem;
  @media screen and (max-width: 535px) {
    padding: 3.2rem 1.6rem 4.8rem;
  }
}

@mixin project-padding {
  padding: 4rem 0;
}

@mixin footer {
  padding: 2.4rem 4.5rem 4rem;
  @include max-width-rem;
  @media screen and (max-width: 535px) {
    padding: 2.4rem 1.6rem 4rem;
  }
}

@mixin icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  background-color: $white;
  border-radius: 50%;
  width: 2.8rem;
  height: 2.8rem;
  transition: all 0.3s;

  &:hover {
    background-color: $dark-blue;
  }
}

//*** LISTS
@mixin list-style {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1.6rem;
}

//*** DIVIDERS
@mixin divider {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;
  width: 100%;
  border-top: 0.2rem solid $light-blue;
}

@mixin divider--vertical {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: rem;
  border-right: 0.2rem solid $light-blue;
}

//*** FORM
@mixin label {
  @include flex-c-c;
  background-color: $dark-blue;
  color: $light-blue-tint-2;
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0.2rem 0.4rem;
}

@mixin form-inputs {
  max-width: 53.5rem;
  min-width: 20rem;
}

@mixin form-text-area {
  @include form-inputs;
  max-height: 15.4rem;
  resize: none;
}

@mixin btn {
  background-color: $dark-blue;
  color: $accent-yellow;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  padding: 1rem 2.4rem;
  border: solid 0.1rem $accent-yellow;
  @include border-radius;
}

//*** FLEX CENTER CENTER
@mixin flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

//*** BORDER-RADIUS
@mixin border-radius {
  border-radius: 0.4rem;
}

//*** BOX SHADOW
@mixin box-shadow {
  box-shadow: 0.2rem 0.2rem 0.4rem $shadow;
}
