@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.footer {
  @include footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.2rem;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.6rem;
  }

  &__heading {
    @include section-subheading--yellow;
    margin: 0;
    text-transform: uppercase;

    & .remainingChars {
      font-size: 2rem;
    }
  }

  &__logo {
    @include logo;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__container {
    @include icon-container;
  }

  &__icon {
    @include icon;
    color: $dark-blue;
  }
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;

  &__copy {
    display: flex;
    align-items: center;
  }

  &__symbol {
    padding: $pad-half $pad-half 0 0;
    font-size: 2.4rem;
  }
}
