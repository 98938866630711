@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Light"), local("TitilliumWeb-Light"),
    url("../../assets/fonts/TitilliumWeb-Light.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web ExtraLight Italic"),
    local("TitilliumWeb-ExtraLightItalic"),
    url("../../assets/fonts/TitilliumWeb-ExtraLightItalic.woff2")
      format("woff2"),
    url("../../assets/fonts/TitilliumWeb-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"),
    url("../../assets/fonts/TitilliumWeb-Bold.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web ExtraLight"), local("TitilliumWeb-ExtraLight"),
    url("../../assets/fonts/TitilliumWeb-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Black"), local("TitilliumWeb-Black"),
    url("../../assets/fonts/TitilliumWeb-Black.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Bold Italic"), local("TitilliumWeb-BoldItalic"),
    url("../../assets/fonts/TitilliumWeb-BoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web SemiBold Italic"),
    local("TitilliumWeb-SemiBoldItalic"),
    url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web SemiBold"), local("TitilliumWeb-SemiBold"),
    url("../../assets/fonts/TitilliumWeb-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Italic"), local("TitilliumWeb-Italic"),
    url("../../assets/fonts/TitilliumWeb-Italic.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Light Italic"), local("TitilliumWeb-LightItalic"),
    url("../../assets/fonts/TitilliumWeb-LightItalic.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"),
    url("../../assets/fonts/TitilliumWeb-Regular.woff2") format("woff2"),
    url("../../assets/fonts/TitilliumWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
