@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.section {
  @include background-light;

  &__container {
    @include section-padding;
  }

  &__heading {
    @include section-heading;
  }
}
